import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

const RHFTest = () => {
	const { register, handleSubmit, errors } = useForm();
	const onSubmit = (data) => console.log(data);

	useEffect(() => {
		console.log(errors);
	}, [errors]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className='max-w-sm rounded px-6 py-4 shadow-lg mx-8 my-8'>
			<div className='mt-1 flex rounded-md shadow-sm my-2'>
				<span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 text-gray-500 bg-red-50 text-sm'>
					First Name
				</span>
				<input
					type='text'
					placeholder='First name'
					name='First name'
					ref={register({ required: true, maxLength: 80 })}
					className='form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5'
				/>
			</div>
			<div className='my-2'>
				<input
					type='text'
					placeholder='Last name'
					name='Last name'
					ref={register({ required: true, maxLength: 100 })}
					className='form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5'
				/>
			</div>
			<div className='my-2'>
				<input
					type='text'
					placeholder='Email'
					name='Email'
					ref={register({ required: true, pattern: /^\S+@\S+$/i })}
					className='form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5'
				/>
			</div>
			<div className='my-2'>
				<input
					type='tel'
					placeholder='Mobile number'
					name='Mobile number'
					ref={register({ required: true, minLength: 6, maxLength: 12 })}
					className='form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5'
				/>
			</div>
			<br />
			<div className='my-2'>
				<label className='block text-sm font-medium leading-5 text-gray-700 mr-4'>
					Prefered Salutation
				</label>
				<select name='Title' ref={register({ required: true })}>
					<option value='Mr'>Mr</option>
					<option value='Mrs'>Mrs</option>
					<option value='Miss'>Miss</option>
					<option value='Dr'>Dr</option>
				</select>
			</div>
			<div>
				<label
					htmlFor='price'
					className='block text-sm leading-5 font-medium text-gray-700'>
					Price
				</label>
				<div className='mt-1 relative rounded-md shadow-sm'>
					<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
						<span className='text-gray-500 sm:text-sm sm:leading-5'>$</span>
					</div>
					<input
						id='price'
						className='form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5'
						placeholder='0.00'
					/>
					<div className='absolute inset-y-0 right-0 flex items-center'>
						<select
							aria-label='Currency'
							className='form-select h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm sm:leading-5'>
							<option>USD</option>
							<option>CAD</option>
							<option>EUR</option>
						</select>
					</div>
				</div>
			</div>
			<div className='flex-1'>
				<span className='mr-4'>Are you a developer?</span>
				<label>Yes</label>
				<input
					name='Developer'
					type='radio'
					value='Yes'
					ref={register({ required: true })}
				/>
				<label className='ml-2'>No</label>
				<input
					name='Developer'
					type='radio'
					value='No'
					ref={register({ required: true })}
				/>
			</div>

			<input type='submit' />
		</form>
	);
};

export default RHFTest;
