import { Helmet } from 'react-helmet';

import '../assets/landing_medicare.css';
import patienthero from '../assets/img/san_medicare_landing_opt.svg';
import { Link } from 'react-router-dom';

const Landing = () => {
	return (
		<div className='landing_medicare'>
			<Helmet>
				<title>Consult Doctors Digtally in India | Sanjeevn.com</title>
				<meta
					name='description'
					content='Book, Chat & Consult your doctors with Sanjeevn Medicare'
				/>
				<meta
					name='keywords'
					content='make clinic appointments online, online medical consultation, telemedicine, online medicare india'
				/>
				<meta name='robots' content='index, follow' />
				<meta name='copyright' content='sanjeevn.com' />
				<meta name='language' content='EN' />

				<meta name='theme-color' content='#008f68' />
			</Helmet>
			<div className='landing_sections_container'>
				<section className='landing_hero_container'>
					<div className='landing_hero'>
						<div className='hero_heading_container'>
							<h1 className='landing_hero_title'>
								<span className='highlight'>Book</span>,{' '}
								<span className='highlight'>Chat</span>, &{' '}
								<span className='highlight'>Consult</span> <br />
								your doctors - with{' '}
								<span className='highlight_under'>Sanjeevn Medicare</span>
							</h1>
							<div className='hero_cta_explainer_item hero_cta_explainer_item_fullw'>
								<h2 className='hero_cta_explainer_item_title'>
									Get a Consult Online - or - Visit the Clinic
								</h2>
								<div className='hero_cta_explainer_item_btn'>
									<Link className='' to='/'>
										Book Appointment Now{' '}
										<span className='' role='img'>
											🞂
										</span>
									</Link>
								</div>
							</div>
							{/* <div className='hero_cta_explainer'>
							<div className='hero_cta_explainer_item'>
								<h2 className='hero_cta_explainer_item_title'>
									Consult Online
								</h2>
								<p className='hero_cta_explainer_item_content'>
									Consult the best doctors Online from the safety of your home.
								</p>
								<div className='hero_cta_explainer_item_btn'>
									<Link className='' to='/'>
										Find Doctor{' '}
										<span className='' role='img'>
											🞂
										</span>
									</Link>
								</div>
							</div>
							<div className='hero_cta_explainer_item'>
								<h2 className='hero_cta_explainer_item_title'>Visit Clinic</h2>
								<p className='hero_cta_explainer_item_content'>
									Book an appointment with your favourite and nearest doctors.
								</p>
								<div className='hero_cta_explainer_item_btn'>
									<Link className='' to='/'>
										Book Appointment{' '}
										<span className='' role='img'>
											🞂
										</span>
									</Link>
								</div>
							</div>
						</div> */}
						</div>
						<div className='hero_image_container'>
							<img
								src={patienthero}
								alt='Easy to find doctors digitally'
								className=''
								width='640px'
								height='640px'
							/>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default Landing;
