import PropTypes from 'prop-types';
import spin from '../../assets/img/spin-1s-200px.svg';

const Loader = (props) => {
	const { text, size } = props;
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
				margin: '40px',
			}}>
			<img
				src={spin}
				height={size}
				width={size}
				style={{ marginBottom: '12px' }}
				alt={`Loading msg: ${text}`}
			/>
			<p>{text}</p>
		</div>
	);
};

Loader.propTypes = {
	text: PropTypes.string,
};

Loader.defaultProps = {
	text: 'Bringing You Sanjeevn Goodness',
	size: '200px',
};

export default Loader;
