import { useState } from 'react';
import { Link } from 'react-router-dom';

import wordmark from '../../assets/img/sanjeevn_medicare_logomark_optimized.svg';

export const Navlink = (props) => {
	const [is_active, setIsActive] = useState(false);
	const { path, label } = props;

	if (path !== null) {
		return (
			<Link to={path} className={is_active ? 'navlink link_active' : 'navlink'}>
				{label}
			</Link>
		);
	} else return null;
};

const Navbar = (props) => {
	const { links } = props;
	return (
		<div className='app_navbar'>
			<img
				src={wordmark}
				alt='Sanjeevn Medicare Logo'
				className=''
				width='200px'
				height='2.5rem'
			/>
			<div className='nav_links_container'>
				{links?.length > 0 &&
					links.map((link, i) => <Navlink {...link} key={i} />)}
			</div>
		</div>
	);
};

export default Navbar;
