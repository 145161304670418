import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

// import LandingClinic from './pages/LandingClinic';
import Landing from './pages/Landing';
import About from './pages/About';

// import FastRefreshTest from './components/FastRefreshTest';
import TailwindTest from './components/TailwindTest';
import RHFTest from './components/RHFTest';

import './assets/app.css';
import Loader from './components/commons/Loader';
import Navbar from './components/nav/Navbar';

const LandingClinic = lazy(() => import('./pages/LandingClinic'));

const links = [
	{ id: 1, path: '/', label: 'Home' },
	{ id: 2, path: '/clinic', label: 'Clinic' },
	{ id: 3, path: '/about', label: 'About' },
];

function App() {
	return (
		<div className='app_container'>
			<header className='app_header'>
				{/* <img src={logo} className='app_logo' alt='logo' /> */}
				{/* <p>
					Edit <code>src/App.js</code> and save to reload.
				</p> */}
				{/* <a
					className='app_link mx-8 my-8'
					href='https://reactjs.org'
					target='_blank'
					rel='noopener noreferrer'>
					Learn React
				</a> */}
			</header>
			<Navbar links={links} />
			<Suspense
				delay={`50ms`}
				fallback={
					<div style={{ height: '100vh', width: '100%' }}>
						<div className='width_container'>
							<Loader size={100} />
						</div>
					</div>
				}>
				<Routes className='route_container'>
					<Route path='/' element={<Landing />} />
					<Route path='about' element={<About />} />
					<Route path='clinic' element={<LandingClinic />} />
				</Routes>
			</Suspense>

			<div className=''>
				<TailwindTest />
				{/* <br />
				<hr />
				<FastRefreshTest /> */}
				<hr />
				<RHFTest />
			</div>
			{/* Footer */}
		</div>
	);
}

export default App;
