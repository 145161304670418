import logo from '../assets/img/sanjeevn_medicare_logo_optimized.svg';
import Loader from './commons/Loader';

const TailwindTest = () => {
	return (
		<div className='max-w-sm rounded overflow-hidden shadow-lg mx-8 my-8 bg-blue-100'>
			<img className='w-full' src={logo} alt='Display' />
			<Loader />
			<div className='px-6 py-4'>
				<div className='font-bold text-blue-500 text-xl mb-2'>
					Aditya Mishra
				</div>
				<p className='text-gray-700 text-base'>
					When I am not coding or designing i like to chill with a nice book or
					play rpgs <span role='img'>😜</span>
				</p>
			</div>
			<div className='px-2 py-4'>
				<span className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2'>
					#Software Engineer
				</span>
				<span className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2'>
					#Writer
				</span>
				<span className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mt-2 ml-2'>
					#Public Speaker
				</span>
			</div>
		</div>
	);
};

export default TailwindTest;
